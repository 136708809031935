import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledBox = styled(Box)({
  height: "1rem",
});

const StyledBox2 = styled(Box)({
  height: "2rem",
});

const StyledBox4 = styled(Box)({
  height: "4rem",
});

const StyledBox8 = styled(Box)({
  height: "8rem",
});

function Spacer({ i }) {
  switch (i) {
    case 8:
      return <StyledBox8 />;
    case 4:
      return <StyledBox4 />;
    case 2:
      return <StyledBox2 />;
    default:
      return <StyledBox />;
  }
}

export default Spacer;
