import React, { useContext, useState, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import { useNavigate, useLocation } from "react-router-dom";
import { valueData } from "../provider/ValueProvider";
import env from "../env-config";
import { StyledButton, StyledButtonValue } from "../components/Customs";
import Spacer from "../components/Spacer";
import LoadingWrapper from "../components/LoadingWrapper";
import useContentful from "../hooks/useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getAnalytics, logEvent } from "firebase/analytics";
import Wrapper from "../components/Wrapper";
import CustomStepper from "../components/CustomStepper";
import { Alert, AlertTitle, Button, Grid, Typography, styled } from "@mui/material";
import { DEFAULT_LANGUAGE, LangContext } from "../provider/LanguageProvider";
import { firebaseAuth } from "../provider/AuthProvider";

const StyledTypographyValue = styled(Typography)`
  ${(props) => props.theme.typography.h4};
  ${(props) => props.theme.breakpoints.up("md")} {
    ${(props) => props.theme.typography.h2};
  }
`;

function Select() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoading } = useContext(firebaseAuth);

  const { values, handleUpdate, bumpSelectedToAdjusted, initValues, initialized } =
    useContext(valueData);
  const [showVideo, setShowVideo] = useState(env.dev.videos);
  const [index, setIndex] = useState(location.search.replace("?index=", "") * 1);
  const progress = (index / values.length) * 100;
  const selected = values.filter((e) => e.selected === true || e.selected === false);
  const selectedTrue = values.filter((e) => e.selected === true);

  const { language } = useContext(LangContext);
  const fields = useContentful("select");

  const value = values[index];

  const valueTranslation =
    values.length > index
      ? values[index].value.translations[language]
        ? values[index].value.translations[language]
        : values[index].value.translations[DEFAULT_LANGUAGE]
      : {};

  useEffect(() => {
    console.log("Initialized: ", initialized);
    if (initialized) {
      initValues();
    }
  }, [initialized]);

  useEffect(() => {
    if (selected.length < values.length || values.length === 0) {
      return;
    }

    if (selectedTrue.length < 2) {
      navigate(`/no-values`);
      return;
    }

    console.log("selectedTrue.length: ", selectedTrue.length);
    console.log("env.adjusted.max: ", env.adjusted.max);

    if (selectedTrue.length > env.selected.max) {
      navigate(`/reduce`);
      return;
    }

    if (selectedTrue.length > env.adjusted.max) {
      navigate(`/adjust`);
      return;
    }

    bumpSelectedToAdjusted();
    navigate(`/focus`);
  }, [selected, selectedTrue, values]);

  function handleYes(value) {
    handleUpdate(value, { selected: true });
    goNext();
  }

  function handleNo(value) {
    handleUpdate(value, { selected: false });
    goNext();
  }

  function goNext() {
    if (index < values.length) {
      const analytics = getAnalytics();
      logEvent(analytics, "screen_view", {
        screen_name: `selected_index_${index}`,
      });
      setIndex(index + 1);
    }
  }

  if (values.length === 0 || !fields || !value || isLoading) {
    return <LoadingWrapper title="Loading Application..." />;
  }

  if (showVideo) {
    return (
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          {fields.videoTitle}
        </Typography>
        <Spacer />
        {/* <YoutubeEmbed id={fields.videoYoutubeId} /> */}
        <Spacer />
        <Typography gutterBottom>{documentToReactComponents(fields.videoDescription)}</Typography>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            setShowVideo(false);
            window.scrollTo(0, 0);
          }}
        >
          {fields.videoButton}
        </StyledButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <CustomStepper activeStep={0} />
      <Typography variant="h6">{fields.title}</Typography>
      <Spacer i={2} />
      <StyledTypographyValue align="center">{valueTranslation.value}</StyledTypographyValue>
      <Spacer />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} container alignItems="center">
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={() => handleYes(value)}
            startIcon={<CheckIcon />}
          >
            {fields.buttonYes}
          </StyledButtonValue>
        </Grid>

        <Grid item xs={12} sm={6} container alignItems="center">
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            startIcon={<CloseIcon />}
            onClick={() => handleNo(value)}
          >
            {fields.buttonNo}
          </StyledButtonValue>
        </Grid>
      </Grid>
      {value.warning && (
        <>
          <Alert severity="warning">
            <AlertTitle>{fields.warningCaption}</AlertTitle>
            {valueTranslation.warning}
          </Alert>
        </>
      )}
      {/* {value.info && (
        <>
          <Alert severity="info">
            <AlertTitle>{fields.infoCaption}</AlertTitle>
            {value.info}
          </Alert>
        </>
      )} */}
      <Spacer i={2} />
      <LinearProgressWithLabel value={progress} />
      <Spacer />
      {index > 0 && (
        <Button variant="outlined" onClick={() => setIndex(index - 1)}>
          {fields.buttonBack}
        </Button>
      )}
    </Wrapper>
  );
}

export default Select;
