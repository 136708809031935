import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../components/LoadingWrapper";
import { firestoreMethods } from "../firebase/firestoreMethods";
import { firebaseAuth } from "../provider/AuthProvider";

import Spacer from "../components/Spacer";

import formatDateTime from "../lib/formatDateTime";
import StarEvaluation from "../components/StarEvaluation";
import Wrapper from "../components/Wrapper";
import useContentful from "../hooks/useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
`;

const Profile = () => {
  const [data, setData] = useState(null);
  const { user } = useContext(firebaseAuth);
  const fields = useContentful("profile");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        return;
      }

      console.log("Profile -> set user");
      console.log(user);
      setData(await firestoreMethods.getResultsForUser(user));
    };

    fetchData();
  }, [user]);

  if (!user || !data || !fields) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper>
      <Typography variant="h3">{fields.title}</Typography>
      <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
      <Spacer />
      {data.map(({ id, data }) => (
        <Accordion key={id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="div">{formatDateTime(data.createdAt.seconds)}</Typography>
          </AccordionSummary>
          <StyledAccordionDetails display="block">
            <StarEvaluation values={data.values} />
            <Button onClick={() => navigate(`/results/${id}`)} variant="contained">
              Auf die Ergebnis-Seite
            </Button>
          </StyledAccordionDetails>
        </Accordion>
      ))}
    </Wrapper>
  );
};

export default Profile;
