import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { firestoreMethods } from "../firebase/firestoreMethods";
import { firebaseAuth } from "../provider/AuthProvider";
import { getComparator } from "../types/tables";

import { EnhancedTableHead } from "../components/enhanced-table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon, CheckCircleOutlineOutlined as CheckIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import StarEvaluation from "../components/StarEvaluation";
import formatDateTime from "../lib/formatDateTime";
import { valueData } from "../provider/ValueProvider";
import { isEqual } from "lodash";

import { snackNotification } from "../provider/SnackProvider";
import { parse } from "papaparse";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "birthYear",
    numeric: true,
    disablePadding: false,
    label: "GJ",
    sortable: true,
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Geschlecht",
    sortable: true,
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Unternehmen",
    sortable: true,
  },
  {
    id: "isAdmin",
    numeric: false,
    disablePadding: false,
    label: "Admin",
    sortable: true,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
  },
];

const CSV_DELIMITER = ",";

function Users() {
  const [users, setUsers] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selected, setSelected] = useState([]);

  const [errors, setErrors] = useState({});

  const { values, initValues, initialized } = useContext(valueData);
  const { user } = useContext(firebaseAuth);
  const { triggerSnackbar } = useContext(snackNotification);

  const fileInputRef = useRef(null);

  const isAdmin = user !== null && user !== undefined && user.isAdmin;

  // ------------------- Component Hooks -------------------
  useEffect(() => {
    async function loadUsers() {
      const newValues = await firestoreMethods.loadUsers();
      setUsers(newValues);
    }

    loadUsers();
  }, []);

  useEffect(() => {
    if (initialized && (!values || values.length === 0)) {
      initValues();
    }
  }, [initialized, values]);

  // ------------------- general functions -------------------
  const displayName = (user) => {
    return user ? (user.name + " " + user.surname).trim() : "k.A.";
  };

  const validate = () => {
    const newErrors = {};
    let isFormValid = true;
    if (!selectedUser.user.name) {
      newErrors.name = "Bitte geben Sie einen Vornamen ein.";
      isFormValid = false;
    }

    if (!selectedUser.user.surname) {
      newErrors.surname = "Bitte geben Sie einen Nachnamen ein.";
      isFormValid = false;
    }

    if (!selectedUser.user.email) {
      newErrors.email = "Bitte geben Sie eine E-Mail Adresse ein.";
      isFormValid = false;
    }

    if (!selectedUser.user.birthYear) {
      newErrors.birthYear = "Bitte geben Sie ein Geburtsjahr ein.";
      isFormValid = false;
    } else {
      const year = parseInt(selectedUser.user.birthYear);
      if (isNaN(year) || year < 1900 || year > new Date().getFullYear()) {
        newErrors.birthYear = "Bitte geben Sie ein gültiges Geburtsjahr ein.";
        isFormValid = false;
      }
    }

    if (!selectedUser.user.gender) {
      newErrors.gender = "Bitte wählen Sie ein Geschlecht aus.";
      isFormValid = false;
    }

    setErrors(newErrors);
    return isFormValid;
  };

  // ------------------- Component Handlers -------------------
  // const handleTest = async () => {
  //   const fncCreateUser = httpsCallable(functions, "api/hello");
  //   const result = await fncCreateUser({
  //     user: {
  //       name: "Vorname",
  //       surname: "Nachname",
  //       email: "test@test.de",
  //       birthYear: new Date().getFullYear(),
  //     },
  //   });
  //   console.log("result: ", result.data);
  // };

  const handleExportValues = async () => {
    if (selected.length === 0) {
      window.alert("Please select at least one user to export the results.");
      return;
    }

    // load all results for all selected users
    const results = [];
    for (const userId of selected) {
      const user = users.get(userId);
      const userResults = await firestoreMethods.getResultsForUser({
        uid: user.id,
      });

      if (userResults && userResults.length > 0) {
        userResults.forEach((result) => {
          result.docId = userId;
        });
        results.push(...userResults);
      }
    }
    const values = new Set();
    results.forEach((result) => {
      result.data.values.forEach((value) => values.add(value.name));
    });

    const csv = [];
    const header = [
      "Name",
      "Vorname",
      "Geburtsjahr",
      "Geschlecht",
      "Unternehmen",
      "Abteilung",
      "Notiz 1",
      "Notiz 2",
    ];
    values.forEach((value) => header.push(value));
    csv.push(header);
    results.forEach((result) => {
      const row = [];
      const user = users.get(result.docId);
      row.push(user.name);
      row.push(user.surname);
      row.push(user.birthYear);
      row.push(user.gender);
      row.push(user.company || "");
      row.push(user.department || "");
      row.push(user.note1 || "");
      row.push(user.note2 || "");
      values.forEach((valueName) => {
        const val = result.data.values.find((v) => v.name === valueName);
        row.push(val ? val.focused : "");
      });
      csv.push(row);
    });

    const csvData = csv.map((row) => row.join(CSV_DELIMITER)).join("\n");

    const curentDate = new Date().toISOString().split("T")[0];
    // Create a blob from the CSV data and download:
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "value test results - " + curentDate + ".csv"; // specify the file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleSelectUser = async (userId) => {
    if (!userId) {
      return;
    }

    if (selectedUser && selectedUser.id !== userId) {
      const s1User = selectedUser.id ? { ...users.get(selectedUser.id) } : null;
      const s2User = { ...selectedUser.user };
      delete s2User.results;
      delete s1User.results;

      if (!isEqual(s1User, s2User)) {
        const conf = window.confirm(
          "The selected user has been changed! Press OK if you want to discard the changes."
        );
        if (!conf) {
          return;
        }
      }
    }

    const user = { ...users.get(userId) };
    if (userId) {
      user.results = await firestoreMethods.getResultsForUser({ uid: user.id });
      console.log("results: ", user.results);
    }
    setSelectedUser({ id: userId, user });
  };

  const handleCheckRow = (event, userId) => {
    // setChecked(event.target.checked);
    const selectedIndex = selected.indexOf(userId);
    const newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(userId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map(([id, candidate]) => id);

      setSelected(newSelected);
    } else {
      console.log("Selected: none");

      setSelected([]);
    }
  };

  const handleDeleteUser = async (e, userId) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this value?")) {
      // should deactive user instead of deleting
      const user = users.get(userId);
      if (!user) {
        return;
      }
      const result = await firestoreMethods.deleteUser(user.id);
      if (result === "success") {
        const newUsers = new Map(users);
        newUsers.delete(userId);

        if (selected.indexOf(userId) >= 0) {
          const newSelected = [...selected];
          newSelected.splice(selected.indexOf(userId), 1);
          setSelected(newSelected);
        }

        setUsers(newUsers);
        setSelectedUser(null);
      } else {
        triggerSnackbar({
          message: "Der Nutzer konnte nicht gelöscht werden!",
          closeFn: () => {},
        });
      }
      //   if (selectedValue && selectedValue.getId() === valueId) {
      //     setSelectedValue(null);
      //   }
    }
  };

  const handleAddUser = async () => {
    const newUser = {
      name: "",
      surname: "",
      email: "",
      birthYear: new Date().getFullYear(),
    };
    setSelectedUser({ id: "", user: newUser });
  };

  const handleSave = async () => {
    if (selectedUser) {
      if (!validate()) {
        return;
      }

      const newUser = { ...selectedUser.user };
      newUser.email = newUser.email.toLowerCase().trim();

      if (selectedUser.id) {
        const userId = await firestoreMethods.updateUser(selectedUser.id, newUser);
        if (!userId) {
          triggerSnackbar({
            message: "Der Nutzer konnte nicht gespeichert werden!",
            closeFn: () => {},
          });
        } else {
          const newUsers = new Map(users);
          newUsers.set(userId, newUser);
          setUsers(newUsers);
          setSelectedUser({ id: userId, user: newUser });
          triggerSnackbar({
            message: "Die Nutzerdaten wurden gespeichert...",
            closeFn: () => {},
          });
        }
      } else {
        const isExisting = await firestoreMethods.isUserRegistered(selectedUser.user.email);
        if (isExisting) {
          const newErrors = { ...errors };
          newErrors.email = "Diese E-Mail Adresse ist bereits registriert.";
          setErrors(newErrors);
          return;
        }

        const newId = await firestoreMethods.createUser(selectedUser.user);
        if (newId) {
          const newUsers = new Map(users);
          newUsers.set(newId, newUser);
          setUsers(newUsers);
          setSelectedUser({ id: newId, user: newUser });
          triggerSnackbar({
            message: "Ein neuer Nutzer wurde erstellt!",
            closeFn: () => {},
          });
        } else {
          triggerSnackbar({
            message: "Der Nutzer konnte nicht gespeichert werden!",
            closeFn: () => {},
          });
        }
      }
    }
  };

  const doImportUsers = async (loadedUsers) => {
    const newUsers = new Map(users);
    const existingUsers = [];
    const invalidUsers = [];
    for (let user of loadedUsers) {
      if (user.email === "" || user.name === "" || user.surname === "") {
        invalidUsers.push(user.name + " " + user.surname + " (" + user.email + ")");
        continue;
      }
      const isExisting = await firestoreMethods.isUserRegistered(user.email);
      if (isExisting) {
        console.log("User already exists: ", user.email);
        existingUsers.push(user.name + " " + user.surname + " (" + user.email + ")");
        continue;
      }

      const newId = await firestoreMethods.createUser(user);
      if (newId) {
        newUsers.set(newId, user);
      }
    }

    setUsers(newUsers);
    if (invalidUsers.length > 0) {
      window.alert(
        "Folgende Nutzer sind konnten nicht importiert werden:\n" + invalidUsers.join("\n")
      );
    }
    if (existingUsers.length > 0) {
      window.alert("Folgende Nutzer existieren bereits:\n" + existingUsers.join("\n"));
    }
  };

  const handleImportUsers = async (e) => {
    console.log("importing users...");
    if (e.target.files && e.target.files.length > 0) {
      try {
        const file = e.target.files[0];
        console.log("filename: ", e.target.files[0]);

        const text = await file.text();
        console.log(text);
        const results = parse(text, {
          header: true,
          delimiter: CSV_DELIMITER,
        });

        console.log("data: ", results.data);

        const loadedUsers = [];
        for (let row of results.data) {
          const newUser = {
            name: row["name"].trim(),
            surname: row["surname"].trim(),
            email: row["email"].trim(),
            birthYear: row["year of birth"].trim(),
            gender: row["gender"].trim(),
            company: row["company"].trim(),
            department: row["department"].trim(),
          };
          loadedUsers.push(newUser);
        }

        console.log("users: ", loadedUsers);
        doImportUsers(loadedUsers);
      } finally {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleChangeValue = async (e) => {
    const name = e.target.name;
    console.log("target name: ", name);

    if (selectedUser) {
      const newSelectedUser = {
        ...selectedUser,
        user: { ...selectedUser.user, [name]: e.target.value },
      };
      console.log("setting new value: ", newSelectedUser);
      setSelectedUser(newSelectedUser);
    }
  };

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (id) => selected.includes(id);

  const getResultUrl = (resultId) => {
    return window.location.origin + "/results/" + resultId;
  };

  function descendingComparator(a, b, orderBy) {
    const value1 = a[1][orderBy];
    const value2 = b[1][orderBy];

    console.log("a1: ");
    console.log(value1);

    console.log("a2: ");
    console.log(value2);

    console.log("orderBy: " + orderBy);

    if (value1 && value2) {
      switch (orderBy) {
        case "isAdmin":
          if (value2.isAdmin && !value1.isAdmin) {
            return -1;
          } else if (!value2.isAdmin && value1.isAdmin) {
            return 1;
          } else {
            return 0;
          }
        default:
          const v1 = typeof value1 === "string" ? value1.toLowerCase() : value1;
          const v2 = typeof value2 === "string" ? value2.toLowerCase() : value2;
          if (v1 < v2) {
            return 1;
          } else if (v1 > v2) {
            return -1;
          } else {
            return 0;
          }
      }
    } else {
      if (value1 && !value2) {
        return -1;
      } else if (!value1 && value2) {
        return 1;
      }
    }
    return 0;
  }

  const filteredUsers = useMemo(() => {
    if (!users) return [];
    return [...users.entries()].filter(([userId, user]) => {
      const sv = searchValue.toLocaleLowerCase();
      return (
        sv.length < 3 ||
        (user &&
          (displayName(user).toLocaleLowerCase().includes(sv) ||
            (user.email && user.email.toLocaleLowerCase().includes(sv)) ||
            (user.company && user.company.toLocaleLowerCase().includes(sv))))
      );
    });
  }, [searchValue, users]);

  const visibleRows = useMemo(
    () => filteredUsers.slice().sort(getComparator(order, orderBy, descendingComparator)),
    [order, orderBy, filteredUsers]
  );

  useEffect(() => {
    if (selectedUser && !filteredUsers.some(([userId, user]) => userId === selectedUser.id)) {
      setSelectedUser(null);
    }

    if (selected.length > 0) {
      const newSelected = selected.filter((userId) =>
        filteredUsers.some(([id, user]) => id === userId)
      );
      setSelected(newSelected);
    }
  }, [filteredUsers]);

  return (
    <Box
      sx={{
        margin: "1.5rem",
        marginTop: "1rem",
      }}
    >
      <Typography variant="h5" fontWeight={"bold"}>
        User Management
      </Typography>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        {isAdmin && (
          <Box>
            <Box display={"flex"} sx={{ gap: 2 }}>
              <Button variant="outlined" color="primary" onClick={handleAddUser}>
                Add New User
              </Button>
              {/* <Button variant="outlined" color="primary" onClick={handleTest}>
                Test
              </Button> */}
              <Button variant="outlined" color="primary" onClick={handleExportValues}>
                Export Values
              </Button>

              <Button variant="outlined" color="primary" component="label">
                Import Users
                <TextField
                  type="file"
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  accept=".csv"
                  sx={{ display: "none" }}
                  inputRef={fileInputRef}
                  onChange={handleImportUsers}
                />
              </Button>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Suche..."
                value={searchValue}
                onChange={handleSearchValueChange}
                sx={{ minWidth: "250px" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
                minHeight: "400px",
                height: "calc(100vh - 310px)",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  flexBasis: "0",
                  maxWidth: `calc(100% - 450px)`,
                  marginRight: "8px",
                }}
              >
                <TableContainer component={Paper} sx={{ height: "100%" }}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={visibleRows.length}
                      selectable={true}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows.map(([userId, user], index) => {
                        const isItemSelected = isSelected(userId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            key={`row-${index}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() => handleSelectUser(userId)}
                            className={selectedUser?.id === userId ? "selected" : ""}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onChange={(event) => handleCheckRow(event, userId)}
                              />
                            </TableCell>
                            <TableCell scope="row" size="small">
                              {displayName(user)}
                            </TableCell>
                            <TableCell size="small">{user?.birthYear || "k.A."}</TableCell>
                            <TableCell size="small">{user?.gender || "k.A."}</TableCell>
                            <TableCell size="small">{user?.company || ""}</TableCell>
                            <TableCell align="center" size="small">
                              {user?.isAdmin && <CheckIcon />}
                            </TableCell>

                            <TableCell style={{ width: 40 }} size="small">
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  onClick={(e) => handleDeleteUser(e, userId)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* ------------- Work Order Details ------------- */}
              <Box sx={{ width: "48%" }}>
                {selectedUser?.user && (
                  <Card sx={{ marginBottom: "1rem" }}>
                    <CardHeader
                      title={
                        selectedUser.id
                          ? displayName(selectedUser.user) + " (" + selectedUser.user.email + ")"
                          : "Neuer Nutzer"
                      }
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.name}
                            helperText={errors.name}
                            fullWidth
                            required
                            label="Vorname"
                            name="name"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.name || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.surname}
                            helperText={errors.surname}
                            fullWidth
                            label="Nachname"
                            name="surname"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.surname || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.email}
                            helperText={errors.email}
                            fullWidth
                            required
                            label="E-Mail Adresse"
                            name="email"
                            variant="outlined"
                            size="small"
                            disabled={selectedUser.id !== ""}
                            value={selectedUser.user.email || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.birthYear}
                            helperText={errors.birthYear}
                            fullWidth
                            label="Geburtsjahr"
                            name="birthYear"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.birthYear || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.gender}
                            helperText={errors.gender}
                            fullWidth
                            label="Geschlecht"
                            name="gender"
                            variant="outlined"
                            select
                            size="small"
                            value={selectedUser.user.gender || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          >
                            <MenuItem value="M">Männlich</MenuItem>
                            <MenuItem value="F">Weiblich</MenuItem>
                            <MenuItem value="D">Divers</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.company}
                            helperText={errors.company}
                            fullWidth
                            label="Unternehmen"
                            name="company"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.company || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.department}
                            helperText={errors.department}
                            fullWidth
                            label="Abteilung"
                            name="department"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.department || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={!!errors.note1}
                            helperText={errors.note1}
                            fullWidth
                            label="Notiz 1"
                            name="note1"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            value={selectedUser.user.note1 || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={!!errors.note2}
                            helperText={errors.note2}
                            fullWidth
                            label="Notiz 2"
                            name="note2"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            value={selectedUser.user.note2 || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions sx={{ padding: 2 }}>
                      <Box flexGrow={1}></Box>
                      <Button variant="contained" sx={{ mr: 2 }} onClick={handleSave}>
                        Speichern
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedUser(null);
                        }}
                        sx={{ mr: 2 }}
                      >
                        Abbrechen
                      </Button>
                    </CardActions>
                  </Card>
                )}
                {selectedUser?.id && selectedUser.user.results && (
                  <Card>
                    <CardHeader title="Test Ergebnisse" />
                    <CardContent>
                      {selectedUser.user.results.length === 0 && (
                        <Typography variant="body2">Noch keine Ergebnisse vorhanden.</Typography>
                      )}
                      {selectedUser.user.results.map(({ id, data }) => {
                        return (
                          <Accordion key={id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography component="div">
                                  {formatDateTime(data.createdAt.seconds)}
                                </Typography>
                                <Link href={getResultUrl(id)} target="_blank">
                                  <Typography component="div">{getResultUrl(id)}</Typography>
                                </Link>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails display="block">
                              <StarEvaluation focusedValues={data.values} />
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {!isAdmin && (
          <>
            <p>This is a protected page. Only users with the admin role can access it.</p>
          </>
        )}
      </Box>
    </Box>
  );
}

export default Users;
