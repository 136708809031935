import React, { useEffect, useState } from "react";

export const SUPPORTED_LANGUAGES = ["de", "en"];

export const DEFAULT_LANGUAGE = "de";

export const languageMappping = {
  en: "gb",
  de: "de",
};

const LangProvider = (props) => {
  const [language, setLang] = useState(DEFAULT_LANGUAGE);

  useEffect(() => {
    console.log("loading language...");
    const lang = localStorage.getItem("values-lang");
    if (lang) {
      setLang(lang);
    } else {
      setLang(DEFAULT_LANGUAGE);
    }
  }, []);

  const setLanguage = (lang) => {
    let newLang = lang;
    if (!SUPPORTED_LANGUAGES.includes(lang)) {
      newLang = DEFAULT_LANGUAGE;
    }
    setLang(newLang);
    localStorage.setItem("values-lang", newLang);
  };

  return (
    <LangContext.Provider value={{ language, setLanguage }}>{props.children}</LangContext.Provider>
  );
};

export default LangProvider;
export const LangContext = React.createContext();
