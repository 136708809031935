import { CssBaseline, ThemeProvider } from "@mui/material";

import "./App.css";
import "typeface-lato";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useContext } from "react";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Adjust from "./pages/Adjust";
import Select from "./pages/Select";
import Reduce from "./pages/Reduce";
import Focus from "./pages/Focus";
import Result from "./pages/Result";

import SnackProvider from "./provider/SnackProvider";
import Imprint from "./pages/Imprint";
import NoValues from "./pages/NoValues";
import PasswordReset from "./pages/PasswordReset";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Values from "./pages/Values";
import ValueProvider from "./provider/ValueProvider";
import { firebaseAuth } from "./provider/AuthProvider";
import theme from "./theme";
import LangProvider from "./provider/LanguageProvider";
import Users from "./pages/Users";

function App() {
  const { token } = useContext(firebaseAuth);

  return (
    <LangProvider>
      <ValueProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <SnackProvider>
              <Header />

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/no-values" element={<NoValues />} />
                <Route path="/profile" element={token === null ? <Signin /> : <Profile />} />
                <Route path="/values" element={<Values />} />
                <Route path="/select" element={<Select />} />
                <Route path="/reduce" element={<Reduce />} />
                <Route path="/adjust" element={<Adjust />} />
                <Route path="/focus" element={<Focus />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/results/:id" element={<Result />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="/users" element={<Users />} />
              </Routes>

              <Footer />
            </SnackProvider>
          </Router>
        </ThemeProvider>
      </ValueProvider>
    </LangProvider>
  );
}

export default App;
