import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spacer from "../components/Spacer";
import { firebaseAuth } from "../provider/AuthProvider";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { firestoreMethods } from "../firebase/firestoreMethods";
import StarEvaluation from "../components/StarEvaluation";
import Wrapper from "../components/Wrapper";
import { Button, Divider, Typography, styled } from "@mui/material";
import { valueData } from "../provider/ValueProvider";

const StyledButton = styled(Button)`
  margin-bottom: 0.5em;
`;

function Result() {
  const [result, setResult] = useState(null);
  const { id } = useParams();
  const { user } = useContext(firebaseAuth);
  const fields = useContentful("results");

  const { initialized, initValues } = useContext(valueData);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        return false;
      }

      const newResult = await firestoreMethods.getResultForId(id);
      setResult(newResult);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    console.log("Initialized: ", initialized);
    if (initialized) {
      initValues();
    }
  }, [initialized]);

  if (!result || !fields) {
    return <LoadingWrapper />;
  }

  const isTempAuthor = localStorage.getItem(`has-created-values-${id}`);
  const isAuthedAuthor = result.uid === user?.uid && user?.uid !== undefined;
  const isLoggedIn = user !== null && user !== undefined;

  return (
    <Wrapper>
      {/* {(isTempAuthor || isAuthedAuthor) && <CustomStepper activeStep={2} />} */}
      <Typography variant="h6">
        {fields.title}
        {/* {formatDate(result.createdAt.seconds)} */}
      </Typography>
      <StarEvaluation focusedValues={result.values} />

      {(isTempAuthor || isAuthedAuthor) && (
        <>
          <Divider />
          <Spacer />
          <Typography variant="h6">{fields.shareTitle}</Typography>
          <Typography component="div">
            {documentToReactComponents(fields.shareDescription)}
          </Typography>

          <StyledButton
            variant="contained"
            color="primary"
            target="_blank"
            href={`mailto:?subject=${fields.emailSubject}&body=${window.location.href}`}
          >
            {fields.buttonEmailValues}
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            target="_blank"
            href={`mailto:?to=${fields.emailAddressBen}&subject=${fields.emailSubject}&body=${window.location.href}`}
            sx={{ marginLeft: "1em" }}
          >
            {fields.buttonEmailToBen}
          </StyledButton>
          <Spacer />
        </>
      )}
      {isLoggedIn && isAuthedAuthor && (
        <>
          <Divider />
          <Spacer />
          <Typography variant="h6">{fields.savedTitle}</Typography>
          <Typography component="div">
            {documentToReactComponents(fields.savedDescription)}
          </Typography>{" "}
          <Button variant="contained" color="primary" onClick={() => navigate("/profile")}>
            {fields.savedButton}
          </Button>
          <Spacer />
        </>
      )}
    </Wrapper>
  );
}
export default Result;
