import React, { useContext, useEffect } from "react";

import { List, ListItem, ListItemSecondaryAction, Rating } from "@mui/material";
import { styled } from "@mui/system";
import { LangContext } from "../provider/LanguageProvider";
import { valueData } from "../provider/ValueProvider";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)(({ theme }) => ({
  right: 0,
}));

export default function StarEvaluation({ focusedValues }) {
  const { language } = useContext(LangContext);
  const { values, initialized, initValues, getValue } = useContext(valueData);

  const [focused, setFocused] = React.useState([]);

  useEffect(() => {
    if (initialized && (!values || values.length === 0)) {
      initValues();
    }
  }, [initialized, values]);

  useEffect(() => {
    if (values && language && focusedValues) {
      const fcs = focusedValues
        .filter((e) => e.adjusted)
        .sort(function (a, b) {
          if (a.focused > b.focused) return -1;
          if (a.focused < b.focused) return 1;
          return 0;
        });
      setFocused(fcs);
    }
  }, [values, language, focusedValues]);

  return (
    <List>
      {focused.length > 0 &&
        focused.map((value, index) => {
          const cValue = getValue(value.name);
          let valueName = "";
          let valueId = "";
          let old = false;
          if (cValue) {
            valueName = cValue.value.translations[language].value;
            valueId = cValue.id;
          } else {
            valueName = value.name;
            valueId = "vid_" + index;
            old = true;
          }
          return (
            <StyledListItem
              sx={{ fontStyle: old ? "italic" : "normal", color: old ? "gray" : "black" }}
              key={valueId}
            >
              {valueName}
              <StyledListItemSecondaryAction>
                <Rating
                  readOnly
                  value={value.focused}
                  max={value.focused > 0 ? value.focused : 1}
                />
              </StyledListItemSecondaryAction>
            </StyledListItem>
          );
        })}
    </List>
  );
}
