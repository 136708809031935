import React, { useContext, useEffect } from "react";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { firebaseAuth } from "../provider/AuthProvider";
import { snackNotification } from "../provider/SnackProvider";
import logo from "../images/ben-logo1.2blk.png";
import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const StyledA = styled("a")(({ theme }) => ({
  flexGrow: 1,
  cursor: "pointer",
  textDecoration: "none",
  color: theme.palette.secondary.contrastText,
  "&:active, &:visited, &:link": {
    textDecoration: "none",
    color: theme.palette.secondary.contrastText,
  },
}));

const StyledImg = styled("img")(({ theme }) => ({
  width: "88px",
  maxWidth: "200%",
  marginTop: "10px",
  marginBottom: "10px",
}));
function Header() {
  const { handleSignout } = useContext(firebaseAuth);
  const { triggerSnackbar } = useContext(snackNotification);
  const { token, user, isLoading } = useContext(firebaseAuth);
  const authed = token !== null;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("header loading: " + isLoading);
    if (isLoading) return;

    if (!user || !user.registered) {
      console.log("user: ", user);
      //check if signin is in the path
      console.log("header - not authed: " + window.location.pathname);
      if (!window.location.pathname.startsWith("/signin")) {
        console.log("navigate to signin");
        navigate("/signin");
      } else console.log("already on signin page");
    }
  }, [user, isLoading, navigate]);

  const handleMenu = (event) => {
    console.log(user);

    setAnchorEl(event.currentTarget);
  };

  const handleCloseClick = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    window.location.href = "/profile";
  };

  const handleValuesClick = () => {
    window.location.href = "/values";
  };

  const handleUsersClick = () => {
    window.location.href = "/users";
  };

  function handleSignoutClick() {
    if (handleSignout()) {
      console.log("user signed out");
      triggerSnackbar({ message: "Logout erfolgreich!" });
      navigate("/signin");
    }
  }

  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ minHeight: "44px" }}>
        <StyledA href="/">
          <StyledImg src={logo} />
        </StyledA>
        {!authed && (
          <Button variant="outlined" href="/signin" color="primary">
            Login
          </Button>
        )}
        {!authed && (
          <Button variant="contained" href="/signup" color="primary">
            Signup
          </Button>
        )}
        {authed && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              size="large"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleCloseClick}
            >
              <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
              {user && user.isAdmin && <MenuItem onClick={handleValuesClick}>Values</MenuItem>}
              {user && user.isAdmin && <MenuItem onClick={handleUsersClick}>Users</MenuItem>}
              <MenuItem onClick={handleSignoutClick}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Header;
