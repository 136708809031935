import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    console.log("ANALYTICS");

    const analytics = getAnalytics();
    logEvent(analytics, "page_view", {
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location]);
}
