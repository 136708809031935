import { useState, useEffect } from "react";
import shuffleArray from "../lib/shuffleArray";

export default function usePairedValues(values) {
  const adjusted = values.filter((e) => e.adjusted);
  const [paired, setPaired] = useState([]);

  useEffect(() => {
    if (paired.length > 0) {
      // do not shuffle multiple times.
      return;
    }
    let hashes = [];

    let generated = adjusted.map((firstValue) => {
      return adjusted
        .filter((e) => firstValue.name !== e.name)
        .map((secondValue) => {
          //check if this pairing is there.
          const hash = [firstValue.name, secondValue.name].sort().toString();
          if (hashes.indexOf(hash) > -1) {
            return null;
          }

          hashes.push(hash);
          //add them shuffled for more randomness.
          return shuffleArray([firstValue, secondValue]);
        });
    });

    //2. flatten
    generated = generated.flat().filter((x) => x != null);
    //4. now shuffle the array.
    generated = shuffleArray(generated);

    setPaired(generated);
  }, [values]);

  return paired;
}
