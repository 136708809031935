import React, { useContext, useEffect, useState } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import { StyledButton } from "../components/Customs";
import { useLocation, useNavigate } from "react-router-dom";
import { snackNotification } from "../provider/SnackProvider";
import Wrapper from "../components/Wrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import Spacer from "../components/Spacer";
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import {} from "../provider/LanguageProvider";
import { LangContext, SUPPORTED_LANGUAGES, languageMappping } from "../provider/LanguageProvider";

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

// const SIGNIN_STEPS = ["loading", "login", "email"];

const Signin = () => {
  const [activeStep, setActiveStep] = useState("loading");
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    handleSigninEmailLink,
    handleSendSignInLinkToEmail,
    isSignInWithEmailLink,
    user,
    isLoading,
    inputs,
    setInputs,
    errors,
  } = useContext(firebaseAuth);
  const { language, setLanguage } = useContext(LangContext);

  const { triggerSnackbar } = useContext(snackNotification);
  const fields = useContentful("signin");
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (user) {
      const id = localStorage.getItem("temp-values-id");
      setActiveStep("loading");
      if (id) {
        console.log("temp values found. going to results page");
        localStorage.removeItem("temp-values-id");
        triggerSnackbar({
          message: fields.snackbar || "Login erfolgreich - viel Spaß!",
          closeFn: () => navigate(`/results/${id}`),
        });
      } else {
        console.log("no temp values found. going to home page");
        triggerSnackbar({
          message: fields.snackbar || "Login erfolgreich - viel Spaß!",
          closeFn: () => navigate(`/`),
        });
      }
    } else {
      console.log("user is not logged in");
      // user is not signed in
      // check if the user clicked the email link
      if (isSignInWithEmailLink(window.location.href)) {
        console.log("signature is in the email link");
        // now in case user clicks the email link on a different device, we will ask for email confirmation
        let email = localStorage.getItem("signInEmail");
        if (!email) {
          email = window.prompt("Please provide your email");
        }
        handleSigninEmailLink(email, window.location.href);
      } else {
        console.log("signature is not in the email link");
        setActiveStep("login");
      }
    }
  }, [user, isLoading, search, fields, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit");
    console.log("sending email link");
    handleSendSignInLinkToEmail(language, true).then((res) => {
      if (res) {
        setActiveStep("email");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage) {
      setLanguage(newLanguage);
      setAnchorEl(null);
    }
  };

  const getLoadingScreen = () => {
    return <LoadingWrapper title="Loading Application..." />;
  };

  if (!fields || isLoading) {
    return getLoadingScreen();
  }

  console.log("fields: ", fields);

  return (
    <>
      {activeStep === "loading" && getLoadingScreen()}
      {activeStep === "login" && (
        <Wrapper>
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">{fields.title}</Typography>
              <StyledButton
                variant="outlined"
                startIcon={
                  <img
                    src={`https://flagcdn.com/w40/${languageMappping[language]}.png`}
                    alt={language}
                    style={{
                      width: "24px",
                      height: "24px",
                      objectFit: "contain",
                    }}
                  />
                }
                onClick={handleClick}
                className="language-switcher"
              >
                {language}
              </StyledButton>
              <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
              >
                {SUPPORTED_LANGUAGES.map((lang) => (
                  <MenuItem key={lang} onClick={(event) => handleLanguageChange(event, lang)}>
                    <img
                      src={`https://flagcdn.com/w40/${languageMappping[lang]}.png`}
                      alt={lang}
                      style={{
                        width: "24px",
                        height: "24px",
                        objectFit: "contain",
                        marginRight: "8px",
                      }}
                    />
                    {lang.toLocaleUpperCase()}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
            <StyledFormControl>
              <InputLabel htmlFor="email">{fields.emailTitle}</InputLabel>
              <Input
                onChange={handleChange}
                name="email"
                placeholder="email"
                value={inputs.email}
              />
            </StyledFormControl>
            {/* <Spacer />
        <StyledFormControl>
          <InputLabel htmlFor="password">{fields.passwordTitle}</InputLabel>
          <Input
            type="password"
            onChange={handleChange}
            name="password"
            placeholder="password"
            value={inputs.password}
          />
        </StyledFormControl> */}
            {errors.length > 0
              ? errors.map((error, index) => (
                  <Typography id={"e_" + index} color="error">
                    {error}
                  </Typography>
                ))
              : null}
            <Spacer i={2} />
            <Box display="flex" justifyContent="space-between">
              <StyledButton type="submit" variant="contained" color="primary">
                {fields.button}
              </StyledButton>
            </Box>
            <Spacer i={2} />
            {/* <Typography>
          {fields.resetCta}{" "}
          <Link href="/password-reset" color="primary">
            {fields.resetButton}
          </Link>
          ?
        </Typography>
        <Spacer />
        <Typography>
          {fields.signupCta}{" "}
          <Link href="/signup" color="primary">
            {fields.signupButton}
          </Link>
          !
        </Typography> */}
          </form>
        </Wrapper>
      )}
      {activeStep === "email" && (
        <Wrapper>
          <Typography variant="h4">{fields.titleMailSent}</Typography>
          <Typography component="div">
            {documentToReactComponents(fields.descriptionMailSent)}
          </Typography>
        </Wrapper>
      )}
    </>
  );
};

export default Signin;
