function defaultStringComparator(a, b, orderBy) {
  const c1 = a[1][orderBy];
  const c2 = b[1][orderBy];

  if (!orderBy) {
    return 0;
  }

  let res = 0;
  if (c1 && c2) {
    if (c2 < c1) {
      res = -1;
    }
    if (c2 > c1) {
      res = 1;
    }
  } else {
    if (c1 && !c2) {
      res = -1;
    } else if (!c1 && c2) {
      res = 1;
    }
  }

  return res;
}

export function getComparator(order, orderBy, descendingComparator) {
  const comparator = descendingComparator || defaultStringComparator;
  return order === "desc"
    ? (a, b) => comparator(a, b, orderBy)
    : (a, b) => -comparator(a, b, orderBy);
}
