import React from "react";
import { useLocation } from "react-router-dom";
import env from "../env-config";
import Spacer from "./Spacer";
import logo from "../images/bizzfluencer-logo.png";
import { AppBar, Box, Grid, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledFooterBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.secondary.light,
}));

const StyledInnerSpacer = styled(Box)({
  height: "2rem",
});

const StyledOuterSpacer = styled(Box)(({ theme }) => ({
  height: "4rem",
  [theme.breakpoints.up("md")]: {
    height: "6rem",
  },
}));

const StyledFooterWrapper = styled("div")(({ theme }) => ({
  maxWidth: "1024px",
  width: "100%",
  margin: "auto",
  [theme.breakpoints.up("md")]: {
    minHeight: "300px",
  },
}));

const StyledImg = styled("img")({
  height: "5rem",
});

function Footer() {
  const location = useLocation();

  if (location !== location.pathname) {
    return null;
  }

  return (
    <>
      <StyledOuterSpacer />
      <StyledFooterBar component="footer" position="static">
        <StyledFooterWrapper>
          <StyledInnerSpacer />
          <Grid container spacing={0}>
            {env.menu.map((entry) => (
              <Grid item xs={12} sm={4} key={entry.title}>
                <Link href={entry.href} title={entry.title} target={entry.target}>
                  <Typography align="center">{entry.title}</Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Spacer i={4} />
          <Grid item sm={12}>
            <Spacer i={4} />

            <Link href={"https://bizzfluencer.com"}>
              <Typography align="center" variant="h6" gutterBottom>
                <StyledImg src={logo} />
                <br />
                werte.cloud
              </Typography>
              <Spacer i={2} />
              <Typography align="center">&copy; 2021 - all rights reserved.</Typography>
            </Link>
            <Spacer i={2} />
          </Grid>
        </StyledFooterWrapper>
      </StyledFooterBar>
    </>
  );
}
export default Footer;
