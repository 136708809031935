import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
//import { bgBG } from "@mui/material/locale";

export const headerColor = "black";

export const benMainBeige = "#f1ede8";
export const borderRadiusSmall = "20px";
export const borderRadiusLarge = "40px";

const theme = createTheme({
  typography: {
    fontFamily: "Lato, Helvetica, sans-serif",
  },
  palette: {
    primary: { main: benMainBeige },
    secondary: { main: "#000" },
    mode: "light",
    background: {
      paper: benMainBeige,
      default: benMainBeige,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@fontFace": "Lato, Helvetica, sans-serif",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: "black",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusSmall,
          border: "1px solid",
          borderColor: "black",
          backgroundColor: benMainBeige,
          color: "black",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "black",
          "&.Mui-checked": {
            color: "black",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "rgba(0, 0, 0, 0.5);",
            borderRadius: borderRadiusSmall,
          },
          "&:hover fieldset": {
            borderColor: "rgba(0, 0, 0, 1);",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "black",
          borderColor: "black",
          "&::before": {
            width: "2%",
            borderColor: "black",
          },
          "&::after": {
            borderColor: "black",
          },
        },
        light: {
          color: grey[400],
          borderColor: grey[400],
          "&::before": {
            width: "2%",
            borderColor: grey[400],
          },
          "&::after": {
            borderColor: grey[400],
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          width: "100%",
          padding: "16px",
          paddingBottom: "0",
          boxShadow: "none",
          marginBottom: "2em",

          backgroundColor: benMainBeige,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusLarge,
          border: "1px solid",
          boxShadow: "none",

          width: "100%",
          margin: "auto",
          padding: "0 24px",

          "& .MuiButton-root": {
            marginLeft: "16px",
            borderRadius: borderRadiusSmall,
            border: "1px solid",
            backgroundColor: benMainBeige,
            color: "black",
            boxShadow: "none",
            height: "1.75rem",
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "darkgrey",
        },
        bar: {
          backgroundColor: "black",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontWeight: "normal",
          },
          "& .MuiTableCell-head": {
            backgroundColor: headerColor,
            color: "white",
            fontWeight: "bold",
            fontSize: "1rem",
            "& .MuiTableSortLabel-root": {
              backgroundColor: headerColor,
              color: "white",
              fontWeight: "bold",
              fontSize: "1rem",
              "& .MuiTableSortLabel-icon": {
                color: "white",
              },
            },
            "& .MuiButtonBase-root": {
              "&.MuiCheckbox-root": {
                color: "white",
              },
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.selected": {
            backgroundColor: grey[400],
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: headerColor,
          color: "white",

          "& .MuiTypography-root": {
            fontWeight: "bold",
            fontSize: "1rem",
          },
        },
      },
    },
  },
});

export default theme;

//   "& .MuiTextField-root": {
//     "& .MuiSvgIcon-root": {
//       color: "white",
//     },
//     "& .MuiInputBase-root": {
//       color: "white",
//     },

//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         borderColor: "white",
//       },
//       "&:hover fieldset": {
//         borderColor: "white",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "white",
//       },
//     },
//   },
