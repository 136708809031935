import React from "react";
import { Step, StepLabel, Stepper, styled } from "@mui/material";
import useContentful from "../hooks/useContentful";

const StyledStepper = styled(Stepper)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(1),
}));

export default function CustomStepper({ activeStep }) {
  const fields = useContentful("stepper");

  if (!fields) {
    return <div></div>;
  }

  return (
    <StyledStepper activeStep={activeStep}>
      <Step>
        <StepLabel>{fields.select}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{fields.focus}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{fields.adjust}</StepLabel>
      </Step>
    </StyledStepper>
  );
}
