import firebaseApp, { functions } from "./firebaseApp";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { firestoreMethods } from "./firestoreMethods";
import env from "../env-config";
import { httpsCallable } from "firebase/functions";

export const authMethods = {
  // firebase helper methods go here...
  initUserListener: function (token, setUser, setToken, setIsLoading) {
    const auth = getAuth(firebaseApp);

    onAuthStateChanged(auth, (user) => {
      console.log("authchanged");
      if (user) {
        console.log("user logged in. fetching user data...");
        firestoreMethods.getUserType(user.uid).then((userType) => {
          user.isAdmin = userType === "admin" ? true : false;
          user.registered = userType !== "guest" ? true : false;
          setUser(user);
          setIsLoading(false);
        });
      } else {
        if (token) {
          setToken(null);
          localStorage.setItem("token", null);
        }
        setIsLoading(false);
      }
    });
  },

  sendSignInLinkToEmail: async function (email, language, checkIfRegistered, setErrors) {
    console.log("check if registered (" + email + "): " + checkIfRegistered);

    const auth = getAuth(firebaseApp);

    // Check if the usert is registered first
    if (checkIfRegistered) {
      email = email.trim();
      console.log("check if registered: " + email);
      const isUserRegistered = await firestoreMethods.isUserRegistered(email);
      if (!isUserRegistered) {
        setErrors((prev) => [...prev, "Email is not registered."]);
        return false;
      }
    }

    console.log("Email is registered.");

    language = language ? language : "de";
    const locale = language === "de" ? "de-DE" : "en-US";
    const date = new Date();
    const loginTime = `${date.toLocaleDateString(locale, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })} - ${date.toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" })}`;

    const sendSignInLink = httpsCallable(functions, "api/sendLoginLink");
    console.log("calling sendSignInLink");
    const response = await sendSignInLink({
      url: env.dev.signin_domain,
      email,
      language,
      loginTime,
    });

    if (response.data.status === "OK") {
      localStorage.setItem("signInEmail", email);

      console.log("Email sent successfully.");
      return true;
    } else {
      setErrors(["Error sending email link."]);
      return false;
    }
    // const actionCodeSettings = {
    //   //url: "https://valuetest.ben.partners/signin/",
    //   url: env.dev.signin_domain,
    //   handleCodeInApp: true,
    // };

    // try {
    //   await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    //   localStorage.setItem("signInEmail", email);
    //   return true;
    // } catch (err) {
    //   setErrors(["Error sending email link."]);
    //   return false;
    // }
  },

  signInWithEmailLink: async function (email, url, setErrors, setToken) {
    const auth = getAuth(firebaseApp);

    signInWithEmailLink(auth, email, url)
      .then((user) => {
        const token = user.user.accessToken;
        localStorage.setItem("token", token);
        localStorage.removeItem("signInEmail");
        setToken(token);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },

  isSignInWithEmailLink: function (url) {
    console.log("check url for signin signature: " + url);
    const res = isSignInWithEmailLink(getAuth(firebaseApp), url);
    console.log("res:", res);
    return res;
  },

  signupEmail: function (email, password, setErrors, setToken) {
    const auth = getAuth(firebaseApp);
    createUserWithEmailAndPassword(auth, email, password)
      //make res asynchronous so that we can make grab the token before saving it.
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b;
        //set token to localStorage
        await localStorage.setItem("token", token);
        //grab token from local storage and set to state.
        setToken(window.localStorage.token);
        console.log(res);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },
  signinEmail: function (email, password, setErrors, setToken) {
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, email, password)
      //everything is almost exactly the same as the function above
      .then(async (res) => {
        const token = await Object.entries(res.user)[5][1].b;
        //set token to localStorage
        await localStorage.setItem("token", token);
        setToken(window.localStorage.token);
        console.log(res);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },
  passwordResetEmail: function (email, setErrors) {
    const auth = getAuth(firebaseApp);
    var actionCodeSettings = {
      url: "http://localhost:20609?email=user@example.com",
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(function () {
        // Password reset email sent.
        console.log("password reset email sent");
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
      });
  },
  signout: function (setErrors, setToken) {
    const auth = getAuth(firebaseApp);
    console.log("signing out");
    // signOut is a no argument function
    signOut(auth)
      .then((res) => {
        //remove the token
        localStorage.removeItem("token");
        //set the token back to original state
        setToken(null);
        localStorage.clear();
      })
      .catch((err) => {
        //there shouldn't every be an error from firebase but just in case
        setErrors((prev) => [...prev, err.message]);
        //whether firebase does the trick or not i want my user to do there thing.
        localStorage.removeItem("token");
        setToken(null);
        console.error(err.message);
      });
    return true;
  },
};
