import React, { useContext, useState } from "react";
import { StyledButton } from "../components/Customs";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Wrapper from "../components/Wrapper";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { LangContext, SUPPORTED_LANGUAGES } from "../provider/LanguageProvider";
import { languageMappping } from "../provider/LanguageProvider";

function Home(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { language, setLanguage } = useContext(LangContext);
  const fields = useContentful("home");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage) {
      setLanguage(newLanguage);
      setAnchorEl(null);
    }
  };

  if (!fields) {
    return <LoadingWrapper title="Loading Application..." />;
  }

  return (
    <Wrapper>
      <Typography variant="h3" align="center">
        {fields.title}
      </Typography>
      {/* <YoutubeEmbed id={fields.youtubeId} /> */}
      <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
      <Box display="flex" justifyContent="space-between">
        <StyledButton variant="contained" href="/select" color="primary">
          {fields.button}
        </StyledButton>
        <StyledButton
          variant="outlined"
          startIcon={
            <img
              src={`https://flagcdn.com/w40/${languageMappping[language]}.png`}
              alt={language}
              style={{
                width: "24px",
                height: "24px",
                objectFit: "contain",
              }}
            />
          }
          onClick={handleClick}
          className="language-switcher"
        >
          {language}
        </StyledButton>
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
        >
          {SUPPORTED_LANGUAGES.map((lang) => (
            <MenuItem key={lang} onClick={(event) => handleLanguageChange(event, lang)}>
              <img
                src={`https://flagcdn.com/w40/${languageMappping[lang]}.png`}
                alt={lang}
                style={{ width: "24px", height: "24px", objectFit: "contain", marginRight: "8px" }}
              />
              {lang.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Wrapper>
  );
}

export default Home;
