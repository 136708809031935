import { cloneDeep } from "lodash";

export class Value {
  #id; // private field
  #selected = null;
  #adjusted = false;
  #focused = 0;
  #enabled = true;
  translations = {};

  constructor(id = "new_id", translations = {}) {
    this.#id = id;
    this.translations = { ...translations };
  }

  static fromId(id) {
    return new Value(id);
  }

  static fromFirestore(doc) {
    const value = new Value(doc.id);
    const data = doc.data();
    value.translations = data.translations;
    value.enabled = data.enabled;
    return value;
  }

  toFirestore() {
    const enabled = this.#enabled !== undefined ? this.#enabled : true;
    return {
      enabled,
      translations: this.translations,
    };
  }

  get id() {
    return this.#id;
  }

  set id(id) {
    this.#id = id;
  }

  get enabled() {
    return this.#enabled;
  }

  set enabled(value) {
    this.#enabled = value;
  }

  // Getter for selected
  get selected() {
    return this.#selected;
  }

  // Setter for selected
  set selected(value) {
    this.#selected = value;
  }

  // Getter for adjusted
  get adjusted() {
    return this.#adjusted;
  }

  // Setter for adjusted
  set adjusted(value) {
    this.#adjusted = value;
  }

  // Getter for focused
  get focused() {
    return this.#focused;
  }

  // Setter for focused
  set focused(value) {
    this.#focused = value;
  }

  getTranslations() {
    return this.translations;
  }

  getTranslation(language) {
    return this.translations[language];
  }

  addTranslation(language, translation) {
    this.translations[language] = translation;
  }

  changeTranslation(language, field, newValue) {
    if (!this.translations[language]) {
      this.translations[language] = {};
    }
    if (this.translations[language]) {
      this.translations[language][field] = newValue;
    }
  }

  clone() {
    const newTranslations = cloneDeep(this.translations);
    return new Value(this.#id, newTranslations);
  }
}
