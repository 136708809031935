import React from "react";

import Spacer from "./Spacer";
import Wrapper from "./Wrapper";
import { Box, LinearProgress, Typography } from "@mui/material";

export default function LoadingWrapper({ title }) {
  return (
    <Wrapper>
      <Spacer />
      <LinearProgress />
      <LinearProgress color="primary" />
      <Spacer />
      {title && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          marginTop="48px"
        >
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
    </Wrapper>
  );
}
