import React, { useContext, useState, useEffect } from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import { useNavigate } from "react-router-dom";
import { valueData } from "../provider/ValueProvider";
import env from "../env-config";
import { StyledButton, StyledButtonValue } from "../components/Customs";
import Spacer from "../components/Spacer";
import LoadingWrapper from "../components/LoadingWrapper";
import useContentful from "../hooks/useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getAnalytics, logEvent } from "firebase/analytics";
import Wrapper from "../components/Wrapper";
import CustomStepper from "../components/CustomStepper";
import { Alert, AlertTitle, Box, Button, Grid, Typography, styled } from "@mui/material";
import { DEFAULT_LANGUAGE, LangContext } from "../provider/LanguageProvider";
import { cloneDeep } from "lodash";

const StyledTypographyValue = styled(Typography)`
  ${(props) => props.theme.typography.h4};
  ${(props) => props.theme.breakpoints.up("md")} {
    ${(props) => props.theme.typography.h2};
  }
`;

function Reduce() {
  const navigate = useNavigate();

  const { values, setValues } = useContext(valueData);
  const [reduceCount, setReduceCount] = useState(0);
  const [reduceValues, setReduceValues] = useState([]);
  const [index, setIndex] = useState(-1);

  const { language } = useContext(LangContext);

  const cFields = useContentful("reduce");

  const progress = (index / Math.max(reduceCount, 1)) * 100;
  const value = index > -1 ? reduceValues[index] : {};

  const valueTranslation =
    index > -1 && reduceValues.length > index
      ? reduceValues[index].value.translations[language]
        ? reduceValues[index].value.translations[language]
        : reduceValues[index].value.translations[DEFAULT_LANGUAGE]
      : {};

  // ----------- react hooks -----------
  useEffect(() => {
    console.log("values have changed. resetting index");
    setIndex(-1);
  }, [values]);

  // ----------- Component functions -----------

  function startReduce() {
    console.log("preparing reduced values...");
    const selectedTrue = values.filter((e) => e.selected === true);

    const newValues = cloneDeep(selectedTrue);
    console.log("reduceValues: ", newValues);
    setReduceValues(newValues);
    setReduceCount(newValues.length);
    setIndex(0);
  }

  function handleReduceUpdate(value, update) {
    const newValues = cloneDeep(reduceValues);
    const index = newValues.findIndex((e) => e.name === value.name);

    newValues[index] = { ...newValues[index], ...update };
    setReduceValues(newValues);
  }

  function handleReduceEnd() {
    console.log("ending reduce");
    const newValues = cloneDeep(values);

    reduceValues.forEach((value) => {
      const index = newValues.findIndex((e) => e.name === value.name);
      newValues[index] = { ...value };
    });

    const selectedTrue = newValues.filter((e) => e.selected === true);
    console.log("left selected true: ", selectedTrue.length);

    if (selectedTrue.length > env.adjusted.max) {
      setValues(newValues);
      navigate(`/adjust`);
      return;
    }

    newValues.forEach((value) => {
      if (value.selected) {
        value.adjusted = true;
      }
    });
    setValues(newValues);
    navigate(`/focus`);
  }

  function handleYes(value) {
    handleReduceUpdate(value, { selected: true });

    goNext();
  }

  function handleNo(value) {
    handleReduceUpdate(value, { selected: false });
    goNext();
  }

  function goNext() {
    console.log("go next. current index: ", index);
    if (index < reduceValues.length) {
      const analytics = getAnalytics();
      logEvent(analytics, "screen_view", {
        screen_name: `reduce_index_${index}`,
      });
      if (index === reduceValues.length - 1) {
        handleReduceEnd();
      } else {
        setIndex(index + 1);
      }
    }
  }

  function displayExplanation() {
    return (
      <>
        <Typography component="div">{documentToReactComponents(cFields.description)}</Typography>
        <Box display="flex" justifyContent="space-between">
          <StyledButton variant="contained" color="primary" onClick={startReduce}>
            {cFields.buttonStart}
          </StyledButton>
        </Box>
      </>
    );
  }

  function displayReducer() {
    return (
      <>
        <Typography variant="h6">{cFields.title}</Typography>
        <Spacer i={2} />
        <StyledTypographyValue align="center">{valueTranslation.value}</StyledTypographyValue>
        <Spacer />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} container alignItems="center">
            <StyledButtonValue
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              onClick={() => handleYes(value)}
              startIcon={<CheckIcon />}
            >
              {cFields.buttonYes}
            </StyledButtonValue>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center">
            <StyledButtonValue
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              startIcon={<CloseIcon />}
              onClick={() => handleNo(value)}
            >
              {cFields.buttonNo}
            </StyledButtonValue>
          </Grid>
        </Grid>
        {value.warning && (
          <>
            <Alert severity="warning">
              <AlertTitle>{cFields.warningCaption}</AlertTitle>
              {valueTranslation.warning}
            </Alert>
          </>
        )}
        {/* {value.info && (
        <>
          <Alert severity="info">
            <AlertTitle>{cFields.infoCaption}</AlertTitle>
            {value.info}
          </Alert>
        </>
      )} */}
        <Spacer i={2} />
        <LinearProgressWithLabel value={progress} />
        <Spacer />
        {index > 0 && (
          <Button variant="outlined" onClick={() => setIndex(index - 1)}>
            {cFields.buttonBack}
          </Button>
        )}
      </>
    );
  }

  if (values.length === 0 || !cFields || !value) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper>
      <CustomStepper activeStep={0} />
      {index === -1 && displayExplanation()}
      {index > -1 && displayReducer()}
    </Wrapper>
  );
}

export default Reduce;
